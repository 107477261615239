<template>
  <div class="CalendarYears">
    <LargeButton v-for="year in years" :key="year" :label="`<strong>${year}</strong>`" icon="calendars" @click="setYear(year)" />
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: ["modal", "date", "onApply"],
  data: function() {
    return {
      current: this.date,
      cyear: new Date().getFullYear(),
      ylimit: 0,
      years: [],
    };
  },
  methods: {
    makeYears: function() {
      for (let i = this.cyear; i >= this.ylimit; i--) {
        this.years.push(i);
      }
      return this.years;
    },
    setYear: function(year) {
      let month = dayjs(this.date).format("M");
      let day = dayjs(this.date).format("D");
      let date = `${year}-${month}-${day}`;

      this.modal.close(() => {
        if (Go.is(this.onApply, "Function")) this.onApply(date);
      });
    },
  },
  beforeMount: function() {
    this.ylimit = this.cyear - 100;
    this.makeYears();
  },
};
</script>

<style lang="scss">
.CalendarYears {
  .LargeButtonIcon {
    opacity: 0.5;
  }
}
</style>
